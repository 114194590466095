
import React, { useState, useEffect } from 'react';

const App = () => {
    const [formData, setFormData] = useState({ name: '', email: '' });
    const [submissions, setSubmissions] = useState([]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://react-form-backend-alt.njaiswal78.workers.dev/api/submissions', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                fetchSubmissions();
                setFormData({ name: '', email: '' });
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const fetchSubmissions = async () => {
        try {
            const response = await fetch('https://react-form-backend-alt.njaiswal78.workers.dev/api/submissions');
            const data = await response.json();
            setSubmissions(data);
        } catch (error) {
            console.error('Error fetching submissions:', error);
        }
    };

    useEffect(() => {
        fetchSubmissions();
    }, []);

    return (
        <div style={{ padding: '20px' }}>
            <h1>Simple Form</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Name:</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Email:</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <button type="submit">Submit</button>
            </form>

            <h2>Submissions</h2>
            <ul>
                {submissions.map((submission, index) => (
                    <li key={index}>
                        {submission.name} ({submission.email})
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default App;
    